<template>
	<div class="Register2">

		<div class="title-box">
			<div class="title">{{$t("signup.register.title")}}</div>
		</div>
		<div class="h-bar"></div>

		<div class="register-box">
			<form
				class="frm_signup"
				@submit.prevent="signup"
			>
				<div class="id-info field-set-box">
					<div class="title">{{$t("signup.register.id-info-title")}}</div>
					<PopupInput
						:popupStyle="'signup-register2'"
						v-for="field in fieldset.idInfo"
						:key="field.name"
						:field="field"
						:maxlength="field.maxlength ? field.maxlength : 120"
						:minlength="field.minlength ? field.minlength : 1"
						v-model="field.value"
						@btn-click="onBtnClick"
					/>
				</div>

				<div class="user-info field-set-box">
					<div class="title">{{$t("signup.register.user-info-title")}}</div>
					<PopupInput
						:popupStyle="'signup-register'"
						v-for="field in fieldset.userInfo"
						:key="field.name"
						:field="field"
						:maxlength="field.maxlength ? field.maxlength : 120"
						:minlength="field.minlength ? field.minlength : 1"
						v-model="field.value"
					/>
				</div>

			</form>
		</div>
		<div class="button-box">
			<div class="btn cancel" @click="onCancel()">{{$t("signup.btn.cancel")}}</div>
			<div class="btn next" @click="onSubmit()">{{$t("signup.btn.submit")}}</div>
		</div>

	</div>
</template>

<script>

import AppConfig from '@/App.Config.js'
var gConfig = AppConfig();


import PopupInput from '@/components/PopupInput.vue'

import sha256 from 'crypto-js/sha256';

import CountryCodes from '@/features/CountryCodes.js'
var CCodes = new CountryCodes();


export default {
	name: "Register2",
	components: {
		PopupInput,
	},
	props: {
		param1: {
			type: String,
			default: '',
		},
	},
	beforeMount () {
		console.log("[Register.vue] beforeMout(), route : ", this.$route)
	},
	mounted () {
		console.log("[Register.vue] mounted(), route : ", this.$route)
	},
	beforeUpdate () {
		console.log("[Register.vue] beforeUpdate(), route : ", this.$route);
	},
	updated() {
		console.log("[Register.vue] updated(), route : ", this.$route)
	},
	data () {
		return {
			submitData: null,
			walletAddr: "0xb2baddress", // temp wallet id for B2B members
			fieldset: {
				idInfo: {
					id: {
						name: 'id',
						buttonName: this.$t('signup.register.id-button-name-mobile'),
						buttonDimmed: true,
						checked:false,
						label: this.$t('signup.register.label-id'),
						labelInfo: this.$t('signup.register.icon-id-popup'),
						placeholder: this.$t('signup.register.place-holder-id'),
						type: 'id',
						value: '',
						maxlength: 30,
						minlength: 5,
					},
					password1: {
						name: 'password1',
						checked:false,
						label: this.$t('signup.register.label-pwd'),
						labelInfo: this.$t('signup.register.icon-pwd-popup'),
						placeholder: this.$t('signup.register.place-holder-pwd'),
						type: 'password',
						value: '',
						maxlength: 26,
						minlength: 6,
					},
					password2: {
						name: 'password2',
						checked:false,
						label: this.$t('signup.register.label-pwd-confirm'),
						placeholder: this.$t('signup.register.place-holder-pwd-confirm'),
						type: 'password',
						value: '',
						maxlength: 26,
						minlength: 6,
					},
					referral: {
						name: 'referral',
						checked:false,
						label: this.$t('signup.register.label-referral'),
						placeholder: this.$t('signup.register.place-holder-referral'),
						type: 'text',
						value: '',
						maxlength: 8,
						minlength: 5,
					}
				},
				userInfo: {
					name: {
						name: 'name',
						checked:false,
						label: this.$t('signup.register.label-name'),
						placeholder: this.$t('signup.register.place-holder-name'),
						type: 'text',
						value: '',
						maxlength: 30,
						minlength: 1,
					},
					email: {
						name: 'email',
						checked:false,
						label: this.$t('signup.register.label-email'),
						placeholder: this.$t('signup.register.place-holder-email'),
						type: 'email',
						value: '',
						maxlength: 120,
						minlength: 8,
					}
				},
			},
			ccodes: CCodes.codes,
			title: "",
			name: "",
			value: "",
			values: [],    // option value
			options: [],   // option name
			isActive: false,
			isClickDone: false
		}
	},
	computed: {
	},
	methods : {
		onCancel () {
			this.$router.go(-1);
		},
		onSubmit () {

			var chFS = this.checkFieldSet();
			if(chFS.result==false) {
				this.mxShowToast(this.$t('signup.register.error-form-join')+chFS.message);
				return;
			}

			if(this.isClickDone) {
				return;
			}
			this.isClickDone = true;

			this.submitData = this.getSubmitValues();
			var data = this.submitData;

			_U.callPost({
				url:gConfig.join_url,
				data: data,
				callback: (resp) =>{
					this.isClickDone = false;
					console.log("[Signup.Register] onSubmit()-> resp ", resp);
					if(_U.getIfDefined(resp,'data')=='success') {
							this.$router.push({name:"Signup-Page", params:{page: 'confirm'}});
					}else{
						this.mxShowToast(this.$t('signup.register.error-api-join')+'<br>('+_U.getIfDefined(resp,'data')+')');
					}
				}
			});
		},
		getSubmitValues() {
			var rv = {
				email : this.fieldset.userInfo.email.value,
				account: this.fieldset.idInfo.id.value,
				password: sha256(this.fieldset.idInfo.password1.value).toString(),
				name: this.fieldset.userInfo.name.value,
				recommendedreferrer: this.fieldset.idInfo.referral.value,
				wallet_addr: this.walletAddr,
			}
			return rv;
		},
		checkFieldSet() {

			var rv = {
				result: true,
				message: ''
			};

			for(var infoName in this.fieldset) {
				console.log("=== info: ", infoName)
				for(var itemName in this.fieldset[infoName]) {
					var item = this.fieldset[infoName][itemName]
					console.log("=== === item: ", item)
					if(itemName == 'referral') { // optional field
						item.checked = true;
					}
					if(item.checked==false) {
						rv.result = false;
						rv.message = '<br>('+item.placeholder+')';
						break;
					}
				}
			}
			return rv;
		},
		onBtnClick(fieldName, val) {
			console.log("==== field name =", fieldName, val);
			switch(fieldName) {
				case 'id':
					this.callCheckId(val);
					break;

				case 'email':
					this.callEmail(val);
					break;
			}

		},
		callCheckId(val) {

			var data = {'account' : val};
			console.log("[Signup.Register] callCheckId()-> req ", data);

			_U.callPost({
				url:gConfig.check_account,
				data: data,
				callback: (resp) =>{
					if(_U.getIfDefined(resp,'data')==false) {
						this.mxShowToast(this.$t('signup.register.id-usable'));
						this.fieldset.idInfo.id.checked=true;
						this.fieldset.idInfo.id.errorMsg='';
					}else{
						this.mxShowToast(this.$t('signup.register.id-duplicated'));
					}
				}
			});

		},

		toggleOptionList(){
			this.isActive = !this.isActive;
        },

		setValue(code){
			this.isActive = false; // close
			this.value = code.value;
			this.title = code.name+' ('+code.mcode+')'
			console.log("select-click:", code);
		}

	}
}

</script>

<style lang="scss" scoped>
.Register2 {
	@include FLEXV(center, center);
	width: 100%;
	height: auto;
	& * {
		color: black;
	}
	padding-bottom: gREm(132);

	.title-box {
		@include FLEX(center, flex-start);
		width: 100%;
		height: gREm(40 + 40);
		.title {
			@include FLEX(center, center);
			width: auto;
			height: gRMe(40);
			@include Set-Font($AppFont, gREm(32), gREm(41), #17141a, 600);
		}
	}
	.h-bar {
		width: 100%;
		height: 1px;
		background-color: #dedede;
	}

	.register-box {
		@include FLEXV(flex-start, center);
		width:100%;
		height: auto;
		.frm_signup {
			@include FLEXV(flex-start, center);
			width:100%;
			height: auto;

			.field-set-box {
				@include FLEXV(space-between, flex-start);
				margin-top: gREm(60);
				margin-bottom: gREm(10);
				width: 100%;
				height: auto; //gREm(28px + 24px + 30 + 123 + 2);
				.title {
					width:100%;
					height: gREm(28);
					margin-bottom: gREm(24);
					@include Set-Font($AppFont, gREm(22), gREm(28), #201360, 600);
				}
				.title-desc {
					width:100%;
					height: auto;
					margin-bottom: gREm(24);
					@include Set-Font($AppFont, gREm(15), gREm(26), #a0a0a0);
					a {text-decoration: underline;}
					a:link { color: blue; }
					a:visited { color: green; }
					a:hover { color: red; }
				}
			}
		}
	}

	.button-box {
		@include FLEX(center, center);
		margin-top: gREm(80 - 20);
		.btn {
			@include FLEX(center, center);
			width: gREm(200);
			height: gREm(60);
			border-radius: gREm(6);
			background-color: #ffffff;
			background-image: none;
			@include OnOverTransition();
			cursor: pointer;
			@include Set-Font($AppFont, gREm(18), gREm(22), #ffff);
			&.next {
				background-color: transparent;
				background-image: linear-gradient(256deg, #3504ff, #9f52ff);
				color: #ffffff;
			}
			&.cancel {
				border: solid 1px #8382a7;
				margin-right: gREm(28);
				color: #8382a7;
			}
		}
	}
}

@include media-max($media_small) { // 768
.Register2 {
	@include FLEXV(center, center);
	width: 100vw;
	min-width: 100vw;
	flex-wrap: wrap;
	height: auto;
	& * {
		color: black;
	}
	padding-bottom: gREm(132);
}}

</style>
